<template xmlns="http://www.w3.org/1999/html">
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'company.announcement.list' }">
                Duyuru Yönetimi
              </router-link>
            </li>
            <li>Duyuru Ekle</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>Duyuru Ekle</span></h2>
        </div>
        <div class="section-header-right">
          <button type="submit" @click="saveForm" class="send-btn d-inline-block btn btn-default float-right">
            {{ $t("messages.submit") }} <i class="uil-message"></i>
          </button>
        </div>
      </div>

      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <fieldset class="uk-fieldset">
        <div class="uk-margin">
          <input id="title" class="uk-input" type="text" v-model="title"
            placeholder="Duyuru Başlığını Buraya Yazınız" />
        </div>

        <div class="uk-margin">
          <Summernote @change="value => { updateTemplateCode(value) }" :model="description" :config="{}" height="300px"
            name="test" style="background-color:#fff;margin:0 auto;" class="mb-4"></Summernote>
        </div>
        <hr />
        <div class="form-group row mb-2">
          <label class="col-md-3 col-form-label" for="send_mobile_notification">Mobil Bildirim
            Gönderilsin</label>
          <div class="col-md-1 text-right">
            <label class="uk-switch" for="send_mobile_notification">
              <input type="checkbox" id="send_mobile_notification" v-model="send_mobile_notification" />
              <div class="uk-switch-slider"></div>
            </label>
          </div>
        </div>
        <div class="uk-margin">
          <label for="type">Gönderim Özelleştirme</label>
          <multiple-select-input :model.sync="select_type" :multiple="false" :required="false" :searchable="false"
            :showLabels="false" :values="SendType" :allowEmpty="false" @select="select_type = $event;" />

          <div class="label-container" v-if="select_type.id == 2">
            <div class="label-area d-flex">
              <div>
                <span class="uk-label my-1 d-inline-flex" v-for="(user, index) in checked_users_items"
                  :key="'user_checked_item_box_' + index">
                  <p><i class="uil-user mr-2"></i>{{ user.fullname }}</p>
                  <button class="cursor-pointer ml-2" @click="removeCheckedUser(user.id)">
                    <i class="uil-multiply"></i>
                  </button>
                </span>
                <span class="uk-label my-1 d-inline-flex" v-for="(group, index) in checked_groups_items"
                  :key="'group_checked_item_box_' + index">
                  <p><i class="uil-user mr-2"></i>{{ group.name }}</p>
                  <button type="button" @click="removeCheckedGroup(group.id)" class="cursor-pointer ml-2">
                    <i class="uil-multiply"></i>
                  </button>
                </span>
              </div>
            </div>
            <hr />
            <button type="button" uk-toggle="target: #modal_user_group_add"
              class="btn btn btn-outline-secondary btn-block">
              <i class="uil-plus"></i> Kullanıcı/Grup Ekle
            </button>
            <div id="modal_user_group_add" class="uk-modal-full uk-modal" uk-modal="">
              <div class="uk-modal-footer bggray">
                <h3 class="float-left">
                  <i class="uil-presentation-plus"></i>
                  Kullanıcı/Grup Ekle
                </h3>
                <button class="
                    uk-button uk-button-default
                    small
                    uk-modal-close
                    float-right
                  " type="button">
                  <i class="uil-times"></i> {{ $t("general.close") }}
                </button>
              </div>

              <div class="uk-modal-dialog bggray h-100">
                <div class="uk-padding-large position-relative uk-grid pt-0" uk-grid="">
                  <div class="uk-width-5-5">
                    <UserGroupSelection class="bg-white px-2 py-4 card" :with_description="true"
                      :allow_both_selection="true" :added_users="checked_users" :added_groups="checked_groups"
                      @setCheckedUsers="($event) => {
                        checked_users = $event.ids;
                        checked_users_items = $event.items;
                      }
                        " @setCheckedGroups="($event) => {
                          checked_groups = $event.ids;
                          checked_groups_items = $event.items;
                        }
                          "></UserGroupSelection>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <button type="submit" @click="saveForm" class="send-btn d-inline-block btn btn-default float-right">
            {{ $t("messages.submit") }} <i class="uil-message"></i>
          </button>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import UserGroupSelection from "@/view/components/assignment/UserGroupSelection";
import store from "@/core/services";
import Summernote from "@/view/company/Mail/Summernote";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";

import module, {
  BASE_URL,
  CREATE_ITEM,
  MODULE_NAME,
  ERROR,
} from "@/core/services/store/announcement.module";

export default {
  name: "AddAnnouncement",
  components: { UserGroupSelection, Summernote, MultipleSelectInput },

  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  data() {
    return {
      successMessage: null,
      errorMessages: [],
      title: null,
      send_mobile_notification: 0,
      description: null,
      status_id: 1,
      checked_users_items: [],
      checked_users: [],
      checked_groups_items: [],
      checked_groups: [],
      select_type: { id: 1, name: "Tüm Kullanıcılara Gönder" },
      SendType: [
        {
          id: 1,
          name: "Tüm Kullanıcılara Gönder"
        },
        {
          id: 2,
          name: "Belli Kullancılara/Gruplara Gönder"
        }
      ]
    };
  },
  computed: {
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    updateTemplateCode(value) {
      this.description = value
    },
    saveForm() {
      let self = this;

      if (self.title == null || self.title.length == 0) {
        this.errorMessages.push(
          self.$t("validation.required_field").replace(":NAME", "Başlık")
        );
        self.scrollToTop();
        return false;
      }

      if (self.description == null || self.description.length == 0) {
        this.errorMessages.push(
          self.$t("validation.required_field").replace(":NAME", "Açıklama")
        );
        self.scrollToTop();
        return false;
      }

      if (self.select_type.id != 1)
        if (self.checked_users.length == 0 && self.checked_groups.length == 0) {
          this.errorMessages.push(
            self
              .$t("validation.required_field")
              .replace(":NAME", "Kullanıcı/Grup")
          );
          self.scrollToTop();
          return false;
        }

      let formData = new FormData();
      formData.append("name", self.title);
      formData.append("text", self.description);
      formData.append("send_mobile_notification", self.send_mobile_notification);
      if (self.select_type.id != 1) {
        self.checked_users.forEach((element) => {
          formData.append("users[]", element);
        });
        self.checked_groups.forEach((element) => {
          formData.append("groups[]", element);
        });
      }
      formData.append("status_id", self.status_id == true ? 1 : 0);
      formData.append("all_users_notice", self.select_type.id == 1 ? 1 : 0);

      store
        .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL,
          contents: formData,
        })
        .then((result) => {
          self.scrollToTop();
          if (!this.error) {
            this.successMessage = self.$t("general.successfully_created");
            this.$router.push({ name: "company.announcement.list" });
          } else this.errorMessages.push(this.error);
        });
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    removeCheckedUser(id) {
      let pos = this.getIndex(id, this.checked_users);
      this.checked_users.splice(pos, 1);
    },
    removeCheckedGroup(id) {
      let pos = this.getIndex(id, this.checked_groups);
      this.checked_groups.splice(pos, 1);
    },
    getIndex(id, array) {
      let index = array
        .map(function (item) {
          return item;
        })
        .indexOf(id);
      return index;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    $(".selectpicker").selectpicker();
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal_user_group_add");
    if (modal) modal.$destroy(true);
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 3000);
  },
  watch: {
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
};
</script>
<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
